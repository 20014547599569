<template>
  <div>
    <el-dialog
      title="La liste des tickets"
      width="70%"
      top="9vh"
      :close-on-click-modal="false"
      :show-close="true"
      :close-on-press-escape="false"
      :visible.sync="visible"
      @open="fetchTicket"
      @close="handleDialogClose"
    >
      <div class="dialog_content_height">
        <el-table :data="items" v-loading="listLoading">
          <el-table-column prop="date" label="DATE" width="180">
            <template slot-scope="{ row }">
              <span>{{ row.created_at }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="invoice_no" label="N° TICKET" width="180">
            <template slot-scope="{ row }">
              <span class="text-blue-500">{{ row.number }}</span>
            </template>
          </el-table-column>

          <el-table-column align="center" label="STATUT" width="100">
            <template slot-scope="{ row }">
              <Status :name="row.status" />
            </template>
          </el-table-column>

          <el-table-column
            prop="selling_price"
            label="TOTAL HT "
            align="right"
            min-width="120"
          >
            <template slot-scope="{ row }">
              <span>{{ row.total | moneyFilter }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="selling_price"
            label="TOTAL TTC "
            align="right"
            width="120"
          >
            <template slot-scope="{ row }">
              <span>{{ row.total | moneyFilter }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="total_wt"
            label="MONTANT REÇU "
            width="150"
            align="right"
          >
            <template slot-scope="{ row }">
              <span>{{ row.amount_to_received | moneyFilter }} </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="total_wt"
            label="MONNAIE "
            width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              <span
                >{{ (row.amount_to_received - row.total) | moneyFilter }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="120" label="ACTION" align="right">
            <template slot-scope="{ row }">
              <el-button
                @click="exportTicketPDF(row.number)"
                size="mini"
                type="info"
                icon="el-icon-printer"
                :loading="
                  row.number === exportInvoiceId ? loandExportInvoice : false
                "
                circle
              >
              </el-button>

              <el-button
                v-if="row.status === 'PAID'"
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="cancelTicket(row.number)"
                :loading="
                  row.number === cancelInvoiceId
                    ? loadingCanceledInvoice
                    : false
                "
                circle
              >
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div slot="footer" class="border-t pt-2 dialog-footer">
        <pagination
          v-show="listQuery.total > 0"
          :total="listQuery.total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.size"
          @pagination="fetchTicket()"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getListTicket, cancelTicket } from "@/api/sales2";
import { exportTicketPDF } from "@/api/export";
import Pagination from "@/components/Pagination";
import { toThousandFilter, parseDate } from "@/Filters";
import Status from "@/components/Status";
export default {
  props: {
    ficheNumber: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    moneyFilter: toThousandFilter,
    filterTime: parseDate,
  },
  components: { Pagination, Status },
  data() {
    return {
      items: [],
      listLoading: false,
      cancelInvoiceId: "",
      exportInvoiceId: "",
      loandExportInvoice: false,
      loadingCanceledInvoice: false,
      listQuery: {
        total: 0,
        page: 1,
        size: 8,
        sort: "desc",
        sortDir: "createdAt",
      },
    };
  },
  methods: {
    async fetchTicket() {
      this.listLoading = true;
      getListTicket(this.listQuery, this.ficheNumber).then((res) => {
        setTimeout(() => {
          this.items = res.data.items;

          this.listQuery.total = parseInt(res.data.total_items);
          this.listQuery.page = parseInt(res.data.current_page) + 1;
          this.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    async cancelTicket(number) {
      this.cancelInvoiceId = number;
      this.loadingCanceledInvoice = true;
      await cancelTicket(number)
        .then(() => {
          setTimeout(() => {
            const item = this.items.find((el) => el.number === number);
            item.status = "CANCELED";
            this.loadingCanceledInvoice = false;
          }, 1000);
        })
        .catch((err) => {
          this.loadingCanceledInvoice = false;
          if (err.data.status === 400) {
            this.$message({
              message:
                "Veuillez renseigner les informations obligatoire et les articles",
              type: "warning",
            });
          } else if (err.data.status === 409 || err.data.status === 404) {
            this.$message({
              message: err.data.message,
              type: "danger",
            });
          } else {
            this.$message({
              message: "Erreur serveur ! Merci de contacter l'administrateur",
              type: "danger",
            });
          }
        });
    },
    exportTicketPDF(id) {
      this.exportInvoiceId = id;
      this.loandExportInvoice = true;
      exportTicketPDF(id)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");

          link.href = url;
          link.setAttribute("download", "ticket-" + id + ".pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.loandExportInvoice = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleDialogClose() {
      this.$emit("close-dialog", false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
